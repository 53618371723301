<template>
  <div class="container p-tb-2">
    <div class="row">
      <h2 class="text-center col-lg-12 p-tb-2">
        If you have questions or concerns, please email us at<br />
        <a href="mailto:customer.service@vaporfly.com" class="text-color-purple underlined">
          customer.service@vaporfly.com
        </a>
      </h2>
    </div>
  </div>
</template>

<script>
import MetaMixin from 'Lib/mixins/MetaMixin';

export default {
  name: 'Help',
  mixins: [MetaMixin],
  beforeMount() {
    this.$store.dispatch('setBreadcrumbs', [{ to: this.$route.path, title: 'Help' }]);
  },
};
</script>
